import React from 'react';

import cx from "classnames";

const Typography = ({ variant, styleVariant, className, children }) => {
    if (
        !variant || 
        (
            variant !== 'h1' &&
            variant !== 'h2' &&
            variant !== 'h3' &&
            variant !== 'h4' &&
            variant !== 'h5' &&
            variant !== 'h6' &&
            variant !== 'p' &&
            variant !== 'link-heading'
        )
    ) {
        console.error('Provide a valid variant');
        return null;
    }

    if (variant === 'link-heading') {
        variant = 'p';
    }
    
    if (
        !styleVariant || 
        (
            styleVariant !== 'h1' &&
            styleVariant !== 'h2' &&
            styleVariant !== 'h3' &&
            styleVariant !== 'h4' &&
            styleVariant !== 'h5' &&
            styleVariant !== 'h6' &&
            styleVariant !== 'p'
        )
    ) {
        styleVariant = variant;
    }

    return (
        React.createElement(
            variant,
            {
                className: cx(`${styleVariant}`, className),
                dangerouslySetInnerHTML: { __html: children ? children.replaceAll('\n', '<br />') : ''}
            }
        )
    );
}

export default Typography