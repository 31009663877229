'use client';

import React, { useState, useEffect } from 'react';

import Link from 'next/link';

import { Typography } from '@/components';
import { Each } from '@/components/helpers';

const AlphabetList = ({ type, items, params }) => {
    const [groups, setGroups] = useState(null);
    const [evenOddLinking, setEvenOddLinking] = useState(false);
    const [currentIsEven, setCurrentIsEven] = useState(false);

    useEffect(() => {
        if (items) {
            // Sort alphabetically
            items.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }

                if (a.title > b.title) {
                    return 1;
                }

                return 0;
            });

            // Divide in groups by first letter
            const letterGroups = {};
            items.forEach((item) => {
                const firstLetter = item.title.charAt(0).toUpperCase();

                if (type !== 'all') {
                    if (params.location) {
                        // setEvenOddLinking(true);
                        // setCurrentIsEven(params.location.title[0].charCodeAt(0) % 2 === 0);
                    }

                    // Only add items with the current type
                    const found = item.types.find((type) => type.type_id === params.type.type_id);

                    if (found) {
                        if (!letterGroups[firstLetter] || !letterGroups[firstLetter]['items']) {
                            letterGroups[firstLetter] = [];
                            letterGroups[firstLetter]['items'] = [];
                        }
        
                        // check if firstLetter is even, like B, D, F, etc.
                        if (params.location) {
                            letterGroups[firstLetter].isEven = firstLetter.charCodeAt(0) % 2 === 0;
                        }
                        letterGroups[firstLetter]['items'].push(item);
                    }
                } else {
                    // Add all items
                    if (!letterGroups[firstLetter] || !letterGroups[firstLetter]['items']) {
                        letterGroups[firstLetter] = [];
                        letterGroups[firstLetter]['items'] = [];
                    }
    
                    // setEvenOddLinking(true);
                    letterGroups[firstLetter].isEven = firstLetter.charCodeAt(0) % 2 === 0;
                    letterGroups[firstLetter]['items'].push(item);
                }
            });

            setGroups(letterGroups);
        }
    }, [items]);

    return (
        <div>
            {groups && (
                <>
                    <div className='flex flex-col gap-3 relative'>
                        <hr />

                        <div className="flex gap-3 px-10 max-w-full overflow-x-auto overflow-y-hidden no-scrollbar">
                            <Each of={Object.keys(groups)} render={(letter, index) => (
                                <Link href={`#${letter}`}>
                                    <Typography variant='link-heading' styleVariant="h2" className='m-0'>
                                        {letter}
                                    </Typography>
                                </Link>
                            )} />

                        </div>

                        <hr />

                        <div className='absolute w-10 h-full right-0 bg-gradient-to-r from-transparent to-white-accent' />
                    </div>

                    <div className="mt-12 flex flex-col gap-12">
                        <Each of={Object.keys(groups)} render={(letter, index) => (
                            <div id={letter} className='scroll-mt-28'>
                                <Link href={`#${letter}`}>
                                    <Typography variant='link-heading' styleVariant="h2" className='mb-2'>
                                        {letter}
                                    </Typography>
                                </Link>

                                <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                                    <Each of={groups[letter]['items']} render={(item, index) => (
                                        type === 'all' ? (
                                            <Each of={item.types} render={(type, index) => (
                                                <Link href={`/${type.slug}-${item.slug}`} className='underline w-fit'>
                                                    <Typography variant='p' className='m-0'>
                                                        {`${type.title} ${item.title}`}
                                                    </Typography>
                                                </Link>
                                            )} />
                                        ) : (
                                            params.pathnames[`/${params.type.slug}-${item.slug}`] ? (
                                                evenOddLinking ? (
                                                    currentIsEven ? (
                                                        groups[letter].isEven ? (
                                                            <Typography variant='p' className='m-0'>
                                                                {`${params.type.title} ${item.title}`}
                                                            </Typography>
                                                        ) : (
                                                            <Link href={`/${params.type.slug}-${item.slug}`} className='underline w-fit'>
                                                                <Typography variant='p' className='m-0'>
                                                                    {`${params.type.title} ${item.title}`}
                                                                </Typography>
                                                            </Link>
                                                        )
                                                    ) : (
                                                        groups[letter].isEven ? (
                                                            <Link href={`/${params.type.slug}-${item.slug}`} className='underline w-fit'>
                                                                <Typography variant='p' className='m-0'>
                                                                    {`${params.type.title} ${item.title}`}
                                                                </Typography>
                                                            </Link>
                                                        ) : (
                                                            <Typography variant='p' className='m-0'>
                                                                {`${params.type.title} ${item.title}`}
                                                            </Typography>
                                                        )
                                                    )
                                                ) : (
                                                    <Link href={`/${params.type.slug}-${item.slug}`} className='underline w-fit'>
                                                        <Typography variant='p' className='m-0'>
                                                            {`${params.type.title} ${item.title}`}
                                                        </Typography>
                                                    </Link>
                                                )
                                            ) : (
                                                null
                                            )
                                        )
                                    )} />
                                </div>
                            </div>
                        )} />
                    </div>
                </>
            )}
        </div>
    );
}

export default AlphabetList;